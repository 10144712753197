var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

var cache = function (fn) {
  var called = false,
      store;

  if (!(fn instanceof Function)) {
    called = true;
    store = fn;
    fn = null;
  }

  return function () {
    if (!called) {
      called = true;
      store = fn.apply(this || _global, arguments);
      fn = null;
    }

    return store;
  };
};

exports = cache;
export default exports;