var exports = {};

exports = function eachCombination(alternativesByDimension, callback, combination) {
  if (!combination) combination = [];

  if (combination.length < alternativesByDimension.length) {
    var alternatives = alternativesByDimension[combination.length];

    for (var index in alternatives) {
      combination[combination.length] = alternatives[index];
      eachCombination(alternativesByDimension, callback, combination);
      --combination.length;
    }
  } else callback.apply(null, combination);
};

export default exports;